<template>
  <div>
    <a-form
      class="simiot-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="8" :xxl="6">
          <a-form-item label="">
            <a-input
              v-decorator="['iccid_or_phone_number_or_imsi', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入ICCID或SIMID或手机号或IMSI"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商状态">
            <a-select
              v-decorator="['card_status']"
              :loading="loadingCardStatusOptions"
              @focus="fetchCardStatusOptions"
              allow-clear
            >
              <a-select-option
                v-for="option in cardStatusOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商套餐">
            <a-select
              v-decorator="['carrier_product_id']"
              :loading="loadingCarrierProductOptions"
              @focus="fetchCarrierProductOptions"
              allow-clear
            >
              <a-select-option
                v-for="option in carrierProductOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="共享属性">
            <a-select
              v-decorator="['traffic_share_property']"
              allow-clear
            >
              <a-select-option
                v-for="option in trafficSharePropertyOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="流量属性">
            <a-select
              v-decorator="['traffic_property']"
              allow-clear
            >
              <a-select-option
                v-for="option in trafficPropertyOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="卡片材质">
            <a-select
              v-decorator="['card_type_id']"
              allow-clear
              :loading="loadingCardTypeOptions"
              @focus="fetchCardTypeOptions"
              @change="handleCardTypeChange"
            >
              <a-select-option
                v-for="option in cardTypeOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="卡片尺寸">
            <a-select
              v-decorator="['card_shape_name']"
              allow-clear
              :loading="loadingCardShapeOptions"
            >
              <a-select-option
                v-for="option in cardShapeOptions"
                :key="option.id"
                :value="option.name"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="超套停">
            <a-select
              v-decorator="['is_exceed_package_stop']"
              allow-clear
            >
              <a-select-option key="1" value="1">
                是
              </a-select-option>
              <a-select-option key="0" value="0">
                否
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="生命周期">
            <a-select
              v-decorator="['life_cycle']"
              allow-clear
              @focus="fetchSimCardLifeCycleOptions"
              :loading="loadingLifeCycleOptions"
            >
              <a-select-option
                v-for="option in lifeCycleOptions"
                :key="option.slug"
                :value="option.slug"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="联网状态">
            <a-select
              v-decorator="['net_status']"
              allow-clear
              @focus="fetchNetStatusOptions"
              :loading="loadingNetStatusOptions"
            >
              <a-select-option
                v-for="option in netStatusOptions"
                :key="option.slug"
                :value="option.slug"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="用户业务模式">
            <a-select
              v-decorator="['user_business_type']"
              allow-clear
            >
              <a-select-option
                v-for="option in userBusinessTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="所属客户">
            <a-select
              v-decorator="['agent_id']"
              :show-arrow="false"
              show-search
              allow-clear
              :filter-option="false"
              :not-found-content="loadingAgentOptions ? undefined : null"
              label-in-value
              placeholder="输入客户名称加载选项"
              @search="fetchAgentOptions"
              :loading="loadingAgentOptions"
            >
              <a-spin v-if="loadingAgentOptions" slot="notFoundContent" size="small" />
              <a-select-option
                v-for="option in agentOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="管理停机">
            <a-select
              v-decorator="['is_manage_stop']"
              allow-clear
            >
              <a-select-option key="1" value="1">
                是
              </a-select-option>
              <a-select-option key="0" value="0">
                否
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="实名认证状态">
            <a-select
              v-decorator="['authentication_status']"
              allow-clear
            >
              <a-select-option key="1" value="1">
                已实名
              </a-select-option>
              <a-select-option key="0" value="0">
                未实名
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商测试期">
            <a-range-picker v-decorator="['carrier_test_period_end_date']" />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商沉默期">
            <a-range-picker v-decorator="['carrier_silent_period_end_date']" />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商激活时间">
            <a-range-picker v-decorator="['carrier_activated_at']" />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="入库时间">
            <a-range-picker v-decorator="['imported_at']" />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="8" v-show="expand">
          <a-form-item label="平台激活时间">
            <a-range-picker :show-time="{ format: 'HH:mm:ss' }" format="YYYY-MM-DD HH:mm:ss" v-decorator="['activated_at']" />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formatRangeBeginDate, formatRangeEndDate, formatRangeBeginDateTime, formatRangeEndDateTime } from '@/utils/time'
import {
  findCarrierAccountCardStatusOptions,
  findCardTypeOptions,
  findCardShapeOptions,
  findTrafficSharePropertyOptions,
  findLifeCycleOptions,
  findNetStatusOptions,
  findTrafficPropertyOptions,
  findUserBusinessTypeOptions
} from '@/api/common_options'
import { findCarrierAccountProductOptions } from '@/api/carrier_product'
import { findAgentOptions } from '@/api/agent'

export default {
  name: 'SearchCarrierSimCard',
  props: {
    carrierAccountId: {
      type: Number,
      required: true
    }
  },
  data() {
    this.fetchAgentOptions = this.$lodash.debounce(this.fetchAgentOptions, 200)
    return {
      expand: false,
      trafficSharePropertyOptions: findTrafficSharePropertyOptions(),
      trafficPropertyOptions: findTrafficPropertyOptions(),
      cardStatusOptions: [],
      cardTypeOptions: [],
      carrierProductOptions: [],
      cardShapeOptions: [],
      lifeCycleOptions: [],
      netStatusOptions: [],
      agentOptions: [],
      userBusinessTypeOptions: findUserBusinessTypeOptions(),
      loadingCardStatusOptions: false,
      loadingCarrierProductOptions: false,
      loadingCardTypeOptions: false,
      loadingCardShapeOptions: false,
      loadingLifeCycleOptions: false,
      loadingNetStatusOptions: false,
      loadingAgentOptions: false,
      form: this.$form.createForm(this, { name: 'sim_card_search' })
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    // 加载运营商套餐选项
    fetchCarrierProductOptions() {
      if (this.carrierProductOptions.length === 0) {
        this.loadingCarrierProductOptions = true
        findCarrierAccountProductOptions(this.carrierAccountId).then(res => {
          if (res.code === 0) {
            this.carrierProductOptions = res.data
            this.loadingCarrierProductOptions = false
          }
        })
      }
    },

    // 加载卡片类型选项
    fetchCardTypeOptions() {
      if (this.cardTypeOptions.length === 0) {
        this.loadingCardTypeOptions = true
        findCardTypeOptions().then(res => {
          if (res.code === 0) {
            this.cardTypeOptions = res.data
            this.loadingCardTypeOptions = false
          }
        })
      }
    },

    // 卡片材质变更，加载卡片尺寸
    handleCardTypeChange(cardTypeId) {
      this.loadingCardShapeOptions = true
      // 清空选项
      this.cardShapeOptions = []
      // 清空选项选中的值
      this.form.setFieldsValue({
        card_shape: undefined
      })

      if (cardTypeId) {
        findCardShapeOptions(cardTypeId).then(res => {
          if (res.code === 0) {
            this.loadingCardShapeOptions = false
            this.cardShapeOptions = res.data
          }
        })
      }
    },

    // 加载运营商状态
    fetchCardStatusOptions() {
      if (this.cardStatusOptions.length === 0) {
        this.loadingCardStatusOptions = true
        findCarrierAccountCardStatusOptions(this.carrierAccountId).then(res => {
          if (res.code === 0) {
            this.cardStatusOptions = res.data
            this.loadingCardStatusOptions = false
          }
        })
      }
    },

    // 加载生命周期选项数据
    fetchSimCardLifeCycleOptions() {
      if (this.lifeCycleOptions.length === 0) {
        this.loadingLifeCycleOptions = true
        findLifeCycleOptions().then(res => {
          if (res.code === 0) {
            this.lifeCycleOptions = res.data
            this.loadingLifeCycleOptions = false
          }
        })
      }
    },

    // 加载联网状态选项数据
    fetchNetStatusOptions() {
      if (this.netStatusOptions.length === 0) {
        this.loadingNetStatusOptions = true
        findNetStatusOptions().then(res => {
          if (res.code === 0) {
            this.netStatusOptions = res.data
            this.loadingNetStatusOptions = false
          }
        })
      }
    },

    // 加载所属用户选项数据
    fetchAgentOptions(name) {
      if (!this.$lodash.trim(name)) {
        return
      }

      this.loadingAgentOptions = true
      findAgentOptions({ name: name }).then(res => {
        if (res.code === 0) {
          this.agentOptions = res.data
          this.loadingAgentOptions = false
        }
      })
    },

    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        agent_id: fieldsValue.agent_id && fieldsValue.agent_id.key,
        carrier_test_period_date_begin: formatRangeBeginDate(fieldsValue.carrier_test_period_end_date),
        carrier_test_period_date_end: formatRangeEndDate(fieldsValue.carrier_test_period_end_date),
        carrier_silent_period_date_begin: formatRangeBeginDate(fieldsValue.carrier_silent_period_end_date),
        carrier_silent_period_date_end: formatRangeEndDate(fieldsValue.carrier_silent_period_end_date),
        carrier_activation_time_begin: formatRangeBeginDate(fieldsValue.carrier_activated_at),
        carrier_activation_time_end: formatRangeEndDate(fieldsValue.carrier_activated_at),
        import_time_begin: formatRangeBeginDate(fieldsValue.imported_at),
        import_time_end: formatRangeEndDate(fieldsValue.imported_at),
        activation_time_begin: formatRangeBeginDateTime(fieldsValue.activated_at),
        activation_time_end: formatRangeEndDateTime(fieldsValue.activated_at)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.cardShapeOptions = []
      this.agentOptions = []
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
